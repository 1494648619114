import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  width: number;
  height: number;
  screenName: string;
}

export const Twitter: FC<Props> = ({ width, height, screenName }) => (
  <Wrapper>
    <a
      className="twitter-timeline"
      data-lang="ja"
      data-width={width}
      data-height={height}
      href={`https://twitter.com/${screenName}?ref_src=twsrc%5Etfw`}
    >
      Tweets by dojin_joho
    </a>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
