import React, { FC } from "react";

export const DM: FC = () => (
  <a
    href="https://twitter.com/messages/compose?recipient_id=1235157239075201024&ref_src=twsrc%5Etfw"
    className="twitter-dm-button"
    data-lang="ja"
    data-screen-name="https://twitter.com/dojin_joho"
    data-show-count="false"
  >
    Message @https://twitter.com/dojin_joho
  </a>
);
