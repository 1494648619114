import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import day from "dayjs";
import styled from "styled-components";
import { SEO } from "../components/SEO";
import { Twitter } from "../components/Twitter";
import { IndexQuery } from "../../types/graphql-types";
import background from "../images/background.png";
import logo from "../images/logo.png";
import heart from "../images/heart.png";
import news from "../images/news.png";
import radio from "../images/radio.png";
import sound from "../images/sound.png";
import characters from "../images/characters.png";
import confetti from "../images/confetti.png";
import mail from "../images/mail.svg";
import "ress";
import "./App.css";
import { DM } from "../components/DM";

const Index = () => {
  const { allBroadcastYaml, allFile } = useStaticQuery<IndexQuery>(graphql`
    query Index {
      allBroadcastYaml(sort: { fields: count, order: DESC }) {
        nodes {
          URL
          count
          date
          name
        }
      }
      allFile(filter: { internal: { mediaType: { eq: "text/markdown" } } }) {
        edges {
          node {
            childMarkdownRemark {
              html
            }
            sourceInstanceName
          }
        }
      }
    }
  `);

  const { edges } = allFile;
  const greeting = edges.find((x) => x.node.sourceInstanceName === "greeting");
  const contact = edges.find((x) => x.node.sourceInstanceName === "contact");

  return (
    <>
      <SEO title="同人音楽情報局" />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fredoka+One&family=M+PLUS+Rounded+1c:wght@400;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper>
        <Title>
          <Logo src={logo} alt="同人音楽情報局" />
        </Title>
        <Section>
          <SubTitle icon={heart}>あいさつ</SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: greeting!.node.childMarkdownRemark!.html!,
            }}
          />
        </Section>
        <Section>
          <SubTitle icon={radio}>コンテンツ</SubTitle>
          <TableContainer>
            <Table>
              <tbody>
                {allBroadcastYaml.nodes.map(({ URL, count, date, name }) => {
                  return (
                    <tr key={URL!}>
                      <td>{day(date!).format("YYYY年MM月DD日")}</td>
                      <td>
                        <a href={URL!}>
                          第{count}回放送で{name}さんを紹介しました！
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        </Section>
        <Section>
          <SubTitle icon={news}>Twitter</SubTitle>
          <Twitter screenName="dojin_joho" width={500} height={400} />
        </Section>
        <Section>
          <SubTitle icon={sound}>コンタクト</SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: contact!.node.childMarkdownRemark!.html!,
            }}
          />
          <Mail src={mail} alt="連絡先" />
          <DM />
        </Section>
        <Characters src={characters} alt="キャラクターイラスト" />
      </Wrapper>
    </>
  );
};

const mediaquery = "750px";

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: calc(100vw * 608 / 1920);
  box-sizing: border-box;
  background-size: contain;
  background-image: url("${confetti}"), radial-gradient(
    circle at center bottom,
    #ffffff 25%,
    #fef1de 25%,
    #fef1de 35%,
    #ffe7c1 35%,
    #ffe7c1 48%,
    #ffd495 48%
  );

  @media (min-width: ${mediaquery}) {
    padding: 0;
    background-image: url("${background}");
    background-position: center;
    background-size: cover;
  }
`;

const Section = styled.section`
  width: 90%;
  margin: 2rem auto 3rem auto;
  padding: 2rem 1rem 1rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  position: relative;

  @media (min-width: ${mediaquery}) {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 20%;
    border-radius: 0;
  }

  &:first-of-type {
    @media (min-width: ${mediaquery}) {
      margin-top: 70vh;
    }
  }

  &:last-of-type {
    margin-bottom: 0;

    @media (min-width: ${mediaquery}) {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
    }
  }
`;

const Logo = styled.img`
  width: 80%;
  height: auto;

  @media (min-width: ${mediaquery}) {
    width: 500px;
    min-width: 40%;
  }
`;

const Characters = styled.img`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;

  @media (min-width: ${mediaquery}) {
    display: none;
  }
`;

const Title = styled.h1`
  margin: 1.5rem 0;
  text-align: center;

  @media (min-width: ${mediaquery}) {
    margin: 1rem;
    text-align: left;
  }
`;

const SubTitle = styled.h2<{ icon: string }>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  padding: 0 1rem;
  color: #ff91aa;
  text-shadow: 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black;
  font-family: "Fredoka One", "M PLUS Rounded 1c", sans-serif;
  font-size: 2.3rem;
  font-weight: bold;

  &::before {
    display: inline-block;
    content: "";
    width: 1em;
    height: 1em;
    margin-right: 0.2em;
    background-image: url("${({ icon }) => icon}");
    background-size: contain;
    background-position: center bottom;
  }

  @media (min-width: ${mediaquery}) {
    position: initial;
    transform: initial;
  }
`;

const TableContainer = styled.div`
  max-height: 18rem;
  overflow-y: scroll;
`;

const Table = styled.table`
  & tr {
    display: block;
    margin-bottom: 0.6rem;
  }

  & td {
    display: block;
  }

  & td:nth-child(odd) {
    padding-right: 1rem;
  }

  @media (min-width: ${mediaquery}) {
    & tr {
      display: table-row;
    }

    & td {
      display: table-cell;
    }
  }
`;

const Mail = styled.img`
  display: inline-block;
  height: 1rem;
  margin-right: 1rem;
  vertical-align: text-bottom;
`;

export default Index;
